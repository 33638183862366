@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~startbootstrap-sb-admin-2/css/sb-admin-2.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}

#wrapper #content-wrapper {
  background: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card {
  margin-bottom: 20px;
}

.accordion > .card {
  margin-bottom: 0px;
  overflow: visible;
}

table {
  &.table {
    tr {
      &.header {
        background: #e5efdc;
        font-weight: bold;
      }
    }
    th,
    td {
      color: #212529;
    }
    th {
      button {
        height: auto;
      }
    }
    thead th button {
      height: auto;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-right: 10px;
      &:first-child {
        margin-right: 0px;
      }
    }
    tfoot th {
      vertical-align: middle;
    }
    &.summary {
      thead {
        background: #e5efdc;
        tr th {
          border: 1px solid white;
        }
      }
    }
  }
  tr {
    th,
    td {
      &.actions {
        &.one {
          width: 32px;
        }
        &.two {
          width: 61px;
        }
        &.three {
          width: 94px;
          min-width: 66px;
        }
      }
      &.square {
        width: 49px;
      }
    }
    th {
      &.vertical-text {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      }
    }
  }
}

.page-item {
  cursor: pointer;
}

.page-link {
  cursor: pointer;
  user-select: none;
}

button[type="button"]:disabled {
  cursor: not-allowed;
}

.ql-editor {
  min-height: 200px;
}

.quill {
  .ql-toolbar {
    background: #fff;
    border-top-left-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
  }
  .ql-container {
    background: #fff;
    border-bottom-left-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;
  }
  &.is-invalid {
    .ql-toolbar {
      border: 1px solid #e74a3b;
    }
    .ql-container {
      border: 1px solid #e74a3b;
    }
  }
}

.modal {
  display: block;
}

.ReactModal__Overlay {
  z-index: 2000;
}

.input-group-prepend .btn,
.input-group-append .btn,
.sidebar {
  z-index: 0;
}

.modal-dialog,
.modal-content {
  /* 80% of window height */
  height: calc(100% - 30px);
  z-index: 10000;
}

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}

.card-body .invalid-feedback {
  margin-top: 0.5rem;
  display: block;
}

.btn-action {
  padding: 0px;
  height: 23px;
  vertical-align: baseline;
  text-decoration: none !important;
  border-bottom: none !important;
  &:hover {
    text-decoration: none !important;
    border-bottom: none !important;
  }
}

.react-select:invalid ~ .invalid-feedback,
.react-select:invalid ~ .invalid-tooltip,
.react-select.is-invalid ~ .invalid-feedback,
.react-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.react-datepicker-wrapper ~ .invalid-feedback {
  display: block;
}

.react-dadata ~ .invalid-feedback {
  display: block;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-xxl {
  max-width: 95%;
}

.form-group.required label:after,
.card.required .card-title:after {
  content: " *";
  color: red;
}

.scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.form-group.is-invalid {
  fieldset ~ .invalid-feedback {
    display: inline-block;
  }
}

.input-field-select__option {
  min-height: 40px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  p {
    margin: 0;
  }
}

.dropzone ~ .invalid-feedback {
  display: block;
}

.custom-tooltip {
  margin: 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
}

.custom-tooltip-final-label-style {
  margin: 0;
}

.custom-tooltip-list-style {
  padding: 0;
  margin: 0;
}

.custom-tooltip-final-item-style {
  display: block;
  padding-top: 4;
  padding-bottom: 4;
  color: #000;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.65;
}

.disabled-edit {
  color: #858796;
  pointer-events: none;
  opacity: 0.65;
}

.ReactModal__Overlay--after-open {
  z-index: 1;
}

.modal-title {
  width: 100%;
}


.fc .fc-daygrid-body {
  z-index: 0 !important;
}

.fc-scrollgrid-sync-table tr {
  height: 100px;
}

.fc .fc-popover {
  z-index: 1 !important;
}

.fc-daygrid-event-dot {
  border: 5px solid;
  border-radius: 5px;
}

// .fc-event-title {
//   color: #858796 !important;
// }

.hidden {
  display:none;
}